var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"component c-slideshow c-slideshow--banner"},[_c('div',{staticClass:"c-slideshow__item position-relative"},[_c('div',{staticClass:"c-slideshow__media bg-color-black position-relative z-index-1"},[(
					_vm.props.data[0].primary_image &&
						_vm.props.data[0].primary_image.images.length
				)?_c('picture',{staticClass:"utility-position-center height-100 width-100"},[_vm._l((_vm.props.data[0].primary_image
						.images),function(image,index){return _c('source',{key:index,attrs:{"srcset":_vm.$options.get_image_resizer_url(
							("" + (_vm.$root.proxy) + (image.image))
						),"alt":image.alt,"media":("(min-width: " + (image.breakpoint) + "px)")}})}),_c('img',{staticClass:"object-fit-cover height-100 width-100",attrs:{"src":_vm.$options.get_image_resizer_url(
							("" + (_vm.$root.proxy) + (_vm.props.data[0].primary_image.images[0].image))
						),"alt":_vm.props.data[0].primary_image.images[0].alt}})],2):_vm._e()]),_c('div',{staticClass:"c-slideshow__details header-font line-height-100 position-absolute bottom-0 left-0 padding-b-32 padding-l-32 text-transform-uppercase bp-1024:padding-b-64 bp-1024:padding-l-64 z-index-2"},[_c('div',{staticClass:"c-slideshow__subtitle text-muted-on-dark font-size-16 bp-1024:font-size-30"},[_vm._v(" "+_vm._s(_vm.props.data[0].subtitle)+" ")]),_c('div',{staticClass:"c-slideshow__title text-on-primary font-size-30 bp-1024:font-size-48"},[_vm._v(" "+_vm._s(_vm.props.data[0].title)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }