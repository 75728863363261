<template>
	<section v-if="data && data.length">
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<component :is="template" :data="data" />
	</section>
</template>

<script>
import { get } from '@/helpers';
import SlideshowSlider from './templates/SlideshowSlider';
import SlideshowSliderAlt from './templates/SlideshowSliderAlt';
import SlideshowSliderAltParallax from './templates/SlideshowSliderAltParallax';
import SlideshowBanner from './templates/SlideshowBanner';

export default {
	name: 'Slideshow',

	components: {
		SlideshowSlider,
		SlideshowSliderAlt,
		SlideshowBanner,
		SlideshowSliderAltParallax,
	},

	props: {
		slideshowId: {
			type: Number,
			required: true,
		},
		count: {
			type: Number,
			default: 10,
		},
		start: {
			type: Number,
			default: 0,
		},
		sportId: {
			type: Number,
			default: null,
		},
		dummy: {
			type: Boolean,
			default: false,
		},
		sectionTitle: {
			required: true,
			type: String,
		},
		template: {
			required: true,
			type: String,
		},
	},

	data: () => ({
		data: null,
	}),

	methods: {
		get_data() {
			const request_options = {
				type: 'slideshow',
				count: this.count,
				slideshow_id: this.slideshowId,
			};

			if (this.sportId) {
				request_options.sport = this.sportId;
			}
			if (this.start) {
				request_options.start = this.start;
			}

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			).then(response => {
				response.forEach(data => {
					data.is_open = false;
				});

				this.data = response;
			});
		},
	},

	created() {
		this.get_data();
	},

	watch: {
		$route() {
			this.get_data();
		},
	},
};
</script>
