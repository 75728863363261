<template>
	<div
		class="component c-slideshow c-slideshow--slider position-relative bg-color-surface z-index-2"
	>
		<swiper class="bg-color-lighter" ref="slider" :options="swiper_options">
			<swiper-slide
				class="c-slideshow__item position-relative"
				v-for="slide in data"
				:key="slide.id"
			>
				<div
					class="c-slideshow__media bg-color-dark text-default-on-dark position-relative"
					:class="{
						'c-slideshow__media-gradient': slide.show_gradient,
					}"
				>
					<picture
						class="c-slideshow__image utility-position-cover object-fit-cover"
						v-if="
							slide.primary_image.images &&
								slide.primary_image.images.length
						"
					>
						<source
							class=""
							:srcset="
								$root.get_image_resizer_url(
									`${$root.proxy}${image.image}?format=jpg&quality=80`,
								)
							"
							:alt="image.alt"
							:media="`(min-width: ${image.breakpoint}px)`"
							v-for="(image, index) in slide.primary_image.images"
							:key="index"
						/>
						<img
							class="utility-position-cover object-fit-cover"
							v-lazy="
								$root.get_image_resizer_url(
									`${$root.proxy}${slide.primary_image.images[0].image}?format=jpg&quality=80`,
								)
							"
							:alt="slide.primary_image.images[0].alt"
						/>
					</picture>
					<div
						class="c-slideshow__media-details position-absolute z-index-2 bottom-0 left-0 padding-b-12 padding-x-24 bp-768:display-none"
					>
						<div
							class="c-slideshow__logo margin-b-16"
							v-if="
								slide.secondary_image.images &&
									slide.secondary_image.images.length
							"
						>
							<img
								:src="
									$root.get_image_resizer_url(
										`${$root.proxy}${slide.secondary_image.images[0].image}`,
									)
								"
								:alt="slide.secondary_image.images[0].alt"
								width="60"
							/>
						</div>
						<div
							class="c-slideshow__title font-legacy font-size-30 line-height-100"
						>
							<navigation-link
								v-if="slide.primary_link"
								_class="text-decoration-none text-default-on-dark display-inline-block"
								:url="slide.primary_link"
							>
								{{ slide.title }}
							</navigation-link>
							<span v-else>
								{{ slide.title }}
							</span>
						</div>
					</div>
				</div>
				<div
					class="c-slideshow__details bp-768:z-index-2 bp-768:position-absolute bp-768:bottom-0 bp-768:left-0 bp-768:height-100 bp-768:width-100 bp-768:display-flex bp-768:align-items-center bp-768:text-default-on-dark"
				>
					<div
						class="c-slideshow__details-inner padding-24 bp-768:padding-y-0 bp-1024:padding-x-64"
						style="max-width:700px"
					>
						<div
							class="c-slideshow__logo display-none margin-b-24 bp-768:display-block bp-1024:margin-b-32"
							v-if="
								slide.secondary_image.images &&
									slide.secondary_image.images.length
							"
						>
							<img
								:src="
									$root.get_image_resizer_url(
										`${$root.proxy}${slide.secondary_image.images[0].image}`,
									)
								"
								:alt="slide.secondary_image.images[0].alt"
							/>
						</div>
						<div
							class="c-slideshow__title display-none line-height-100 bp-768:display-block"
						>
							<navigation-link
								v-if="slide.primary_link"
								_class="text-decoration-none text-default-on-dark font-legacy font-size-36 bp-1024:font-size-48"
								:url="slide.primary_link"
							>
								{{ slide.title }}
							</navigation-link>
							<span
								v-else
								class="text-decoration-none text-default-on-dark font-legacy font-size-36 bp-1024:font-size-48"
							>
								{{ slide.title }}
							</span>
						</div>
						<div
							class="c-slideshow__teaser text-muted margin-t-12 bp-768:text-default-on-dark bp-1024:margin-t-16"
							:class="{
								'margin-b-16 bp-768:margin-b-24 bp-1024:margin-b-32':
									slide.links,
							}"
							v-if="slide.teaser"
							v-html="slide.teaser"
						></div>
						<ul
							class="c-slideshow__links reset-list"
							v-if="slide.links"
						>
							<li v-for="link in slide.links" :key="link.link_id">
								<navigation-link
									_class="button --on-dark-alt"
									:url="link.link_url"
								>
									{{ link.link_text }}
								</navigation-link>
							</li>
						</ul>
					</div>
				</div>
			</swiper-slide>
			<div
				class="swiper-button-prev border-b border-t border-r text-brand
					hover:bg-color-primary hover:text-on-primary
					bp-768:display-none 
				"
				slot="button-prev"
				v-if="data.length > 1"
			>
				<arrow-left-icon size="24" />
			</div>
			<div
				class="swiper-button-next border-b border-t text-brand
					hover:bg-color-primary hover:text-on-primary
					bp-768:display-none 
				"
				slot="button-next"
				v-if="data.length > 1"
			>
				<arrow-right-icon size="24" />
			</div>
		</swiper>
		<!-- <arrow-down-icon
			class="c-slideshow__indicator display-none position-absolute left-50 transform-translate-n50 z-index-2
				bp-768:display-block
			"
			color="white"
			size="2x"
		/> -->
		<ol
			class="c-slideshow__pagination display-none reset-list flex-direction-column align-items-center justify-content-center position-absolute z-index-2 bottom-0 right-0 height-100 bp-768:display-flex"
			v-if="data.length > 1"
		>
			<li
				class="c-slideshow__pagination-item cursor-pointer utility-flex-center position-relative"
				:class="{ 'is-active': item.is_open }"
				v-for="(item, index) in data"
				:key="item.id"
				@click="go_to_slide(index)"
			>
				<img
					class="c-slideshow__pagination-thumb position-absolute top-50 right-100"
					:src="
						$root.get_image_resizer_url(
							`${$root.proxy}${item.primary_image.images[0].image}`,
						)
					"
					:alt="item.primary_image.images[0].alt"
					width="128"
					v-if="
						item.primary_image && item.primary_image.images.length
					"
				/>
			</li>
		</ol>
		<button
			class="c-slideshow__pause-play border-t border-b border-l cursor-pointer position-absolute bottom-0 right-0 reset text-brand transition z-index-3
				hover:bg-color-primary hover:text-on-primary
				bp-768:border-none bp-768:text-white
			"
			@click.prevent="swiper_is_playing ? pause_swiper() : play_swiper()"
			v-if="data.length > 1"
		>
			<play-circle-icon v-if="!swiper_is_playing" size="1.5x" />
			<pause-circle-icon v-else size="1.5x" />
		</button>
	</div>
</template>

<script>
import {
	ArrowLeftIcon,
	// ArrowDownIcon,
	ArrowRightIcon,
	PlayCircleIcon,
	PauseCircleIcon,
} from 'vue-feather-icons';
import NavigationLink from '@/components/common/NavigationLink';
export default {
	name: 'SlideshowSlider',

	components: {
		ArrowLeftIcon,
		// ArrowDownIcon,
		ArrowRightIcon,
		NavigationLink,
		PlayCircleIcon,
		PauseCircleIcon,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

	data: () => ({
		swiper_options: {
			touchReleaseOnEdges: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
			autoplay: {
				delay: 10000,
			},
			on: {
				init() {
					// pause on hover; autoplay option did not work.
					this.el.addEventListener('mouseenter', () => {
						this.autoplay.stop();
					});

					this.el.addEventListener('mouseleave', () => {
						this.autoplay.start();
					});
				},
			},
		},
		swiper_is_playing: true,
	}),

	computed: {
		swiper() {
			return this.$refs.slider.$swiper;
		},
	},

	methods: {
		go_to_slide(index) {
			this.swiper.slideTo(index);
		},
		play_swiper() {
			this.swiper.autoplay.start();
			this.swiper_is_playing = true;
		},
		pause_swiper() {
			this.swiper.autoplay.stop();
			this.swiper_is_playing = false;
		},
	},

	mounted() {
		this.data[0].is_open = true;
		this.swiper.on('slideChange', swiper => {
			this.data.forEach(item => (item.is_open = false));
			this.data[swiper.activeIndex].is_open = true;
		});
	},
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	&__indicator {
		animation: bounce 2s infinite;
		bottom: 24px;
	}

	&__media {
		padding-bottom: 250px;

		&-gradient {
			&::after {
				bottom: 0;
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				width: 100%;
				background: linear-gradient(
					180deg,
					rgba(0, 0, 34, 0) 0%,
					rgba(0, 0, 34, 0.8) 100%
				);
				z-index: 1;
			}
		}
	}

	&__pagination {
		background-color: rgba(0, 0, 0, 0.1);
		box-shadow: inset 1px 0px 0px rgba(255, 255, 255, 0.1);
		width: 64px;

		&-item {
			height: 40px;
			width: 40px;

			&::after {
				background-color: #fff;
				border-radius: 50%;
				border: 2px solid #fff;
				content: '';
				height: 8px;
				transition: all 0.25s;
				width: 8px;
			}

			&.is-active::after {
				background-color: transparent;
			}

			&:hover {
				.c-slideshow__pagination-thumb {
					opacity: 1;
					transform: translate(-12px, -50%);
				}
			}
		}

		&-thumb {
			border: 3px solid rgba(0, 0, 0, 0.3);
			border-right-color: var(--color-primary);
			max-width: none;
			opacity: 0;
			pointer-events: none;
			transform: translate(0, -50%);
			transition: all 0.25s;
		}
	}

	&__pause-play {
		height: 64px;
		width: 64px;
	}

	@media screen and (min-width: 768px) {
		&__media {
			padding-bottom: 500px;

			&::after {
				height: 100%;
				width: 100%;
				background: linear-gradient(
					90deg,
					rgba(0, 0, 34, 0.8) 0%,
					rgba(0, 0, 34, 0) 100%
				);
			}
		}
	}
}
</style>

<style lang="scss">
.c-slideshow--slider {
	.swiper-button-prev,
	.swiper-button-next {
		display: inline-flex;
		height: 64px;
		left: auto;
		margin: 0;
		position: relative;
		right: auto;
		top: auto;
		transition: all 0.25s;
		width: calc(50% - 32px);

		&.swiper-button-disabled {
			opacity: 1;

			> svg {
				opacity: 0.35;
			}
		}

		&::after {
			content: none;
		}
	}
}
</style>
